import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'privacy',
    loadComponent: () => import('./pages/privacy/privacy.component').then(m => m.PrivacyComponent),
  },
  {
    path: '',
    loadChildren: () => import('./modules/main/main.routes').then(m => m.routes),
  },
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.routes').then(m => m.routes),
  },
  {
    path: 'clients',
    loadChildren: () => import('./modules/clients/clients.routes').then(m => m.routes),
  },
  {
    path: 'tasks',
    loadChildren: () => import('./modules/tasks/tasks.routes').then(m => m.routes),
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.routes').then(m => m.routes),
  },
];
