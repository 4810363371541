import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    private toast: ToastrService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (this.router.url !== '/login' && (err.status === 401 || err.status === 403)) {
          // auto logout if [401, 403] response returned from api
          this.authenticationService.logout();
          this.router.navigate(['/login']).then();

          this.toast.error('A sua sessão terminou, por favor logue novamente!', 'Não autorizado');
        }

        return throwError(() => err);
      })
    );
  }
}
