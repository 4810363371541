import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideNoopAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, Router, withPreloading } from '@angular/router';
import { provideCharts } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { registerables } from 'chart.js';

import { APP_ROUTES } from './app/app.routes';
import { ErrorInterceptor } from './app/interceptors/error.interceptor';
import { JwtInterceptor } from './app/interceptors/jwt.interceptor';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { LightboxModule } from 'ngx-lightbox';
import { ConfigurationService } from './app/services/configuration/configuration.service';
import { createErrorHandler, init, TraceService } from '@sentry/angular-ivy';
import { environment } from './environments/environment';

if (environment.production) {
  init({
    dsn: 'https://733f4e3fbb9d9dda2c1c2ce3e8470243@o434849.ingest.sentry.io/4506469211897856',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

registerLocaleData(localePt);

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService) => () => configService.loadConfigurations(),
      deps: [ConfigurationService],
      multi: true,
    },
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(ToastrModule.forRoot()),
    importProvidersFrom(LightboxModule),
    provideRouter(APP_ROUTES, withPreloading(PreloadAllModules)),
    provideEnvironmentNgxMask(),
    provideCharts({
      registerables: registerables,
    }),
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ...(environment.production
      ? [
          {
            provide: ErrorHandler,
            useValue: createErrorHandler({
              showDialog: true,
            }),
          },
          {
            provide: TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => console.debug(''),
            deps: [TraceService],
            multi: true,
          },
        ]
      : []),
    provideNoopAnimations(),
  ],
}).then(() => {
  console.debug('Application started');
});
