import { Injectable } from '@angular/core';
import { PermissionsService } from '../permissions/permissions.service';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(
    private permissionService: PermissionsService,
    private authService: AuthService
  ) {}

  public async loadConfigurations() {
    if (this.authService.isValid) {
      return await firstValueFrom(this.permissionService.getAll());
    }

    return Promise.resolve();
  }
}
