import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PermissionsService } from './services/permissions/permissions.service';
import { LocationService } from './services/location/location.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgxSpinnerModule],
})
export class AppComponent implements OnInit {
  constructor(
    private geo: LocationService,
    private permission: PermissionsService
  ) {}

  public async ngOnInit() {
    await this.geo.getLocation();
  }
}
